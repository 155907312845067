import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth } from '../../hooks';
import GenericErrorPage from '../GenericErrorPage';
import { LoadingOverlay } from '../LoadingOverlay';
import { AUTHENTICATE_USER, GET_USER } from './authenticateUserGQL';
import { BeginWorkflow } from './BeginWorkflow';
import { FIND_WORKFLOW_DRAFT } from './findWorkflowDraftGQL';
import { getLocalStorage } from '~/src/utils';
import { DISPATCH_ID_STORAGE_KEY } from '~/src/constants';
import {
  AuthenticatedUser,
  BeginWorkflowParams,
} from '~/src/components/BeginWorkflow/types';

type BeginWorkflowContainerProps = { startParams?: BeginWorkflowParams };

export const BeginWorkflowContainer = ({
  startParams,
}: BeginWorkflowContainerProps) => {
  const dispatchId = getLocalStorage(DISPATCH_ID_STORAGE_KEY) ?? undefined;

  if (!startParams) {
    return null;
  }

  const { loginWithMagicToken, isAuthenticated } = useAuth();
  const [existingDraftClaimId, setExistingDraftClaimId] = useState<
    string | undefined
  >();
  const [authenticatedUser, setAuthenticatedUser] =
    useState<AuthenticatedUser>();

  const [requires2Fac, setRequires2Fac] = useState(false);
  const [showDraftModal, setShowDraftModal] = useState(false);
  const [startWorkflow, setStartWorkflow] = useState(false);

  const [
    findWorkflowDraft,
    { loading: draftLoading, error: draftError, data },
  ] = useMutation(FIND_WORKFLOW_DRAFT, {
    variables: { type: startParams.type },
  });

  const [
    authenticateUser,
    { loading: authenticateLoading, error: authenticateError },
  ] = useMutation(AUTHENTICATE_USER, {
    onCompleted: async res => {
      await loginWithMagicToken(res.authenticateUserByPhone.magicToken);
      setAuthenticatedUser(res.authenticateUserByPhone);
      findWorkflowDraft();
    },
  });

  const { loading: userloading } = useQuery(GET_USER, {
    onCompleted: async res => {
      setAuthenticatedUser(res.me);
      findWorkflowDraft();
    },
  });

  // First try and authenticate the user.  if successful retrieveWorkflows
  useEffect(() => {
    // For CAT, just use the existing auth if possible.
    if (isAuthenticated && startParams.catastropheId) {
      return;
    }

    authenticateUser({
      variables: {
        name: startParams.name,
        phoneNumber: startParams.phoneNumber,
        carrierUserId: startParams.carrierUserId,
        carrierSessionId: startParams.carrierSessionId,
        dispatchId,
      },
    });
  }, []);

  useEffect(() => {
    if (data) {
      const result = data.findWorkflowDraft;
      if (result?.success) {
        setExistingDraftClaimId(result?.data?.claimId);
        setRequires2Fac(result.data?.requires2Fac);
        setShowDraftModal(true);
      } else {
        setStartWorkflow(true);
      }
    }
  }, [data]);

  const error = authenticateError || draftError;
  if (error) {
    return <GenericErrorPage modal error={error} />;
  }
  if (draftLoading || authenticateLoading || userloading) {
    return <LoadingOverlay />;
  }
  return (
    <BeginWorkflow
      startParams={startParams}
      claimId={existingDraftClaimId}
      firstName={authenticatedUser?.firstName}
      setShowDraftModal={setShowDraftModal}
      showDraftModal={showDraftModal}
      startWorkflow={startWorkflow}
      requires2Fac={requires2Fac}
      dispatchId={dispatchId}
    />
  );
};
