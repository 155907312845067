import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { stepComponentHasConfigFlag } from '../../helpers/stepComponentHasConfigFlag';
import {
  escapeComponentJSONFieldName,
  generateValidationSchema,
  unescapeComponentJSONFieldName,
} from '../../helpers/validation';
import {
  ClaimWorkflowStepFragmentData,
  StepComponentRegistry,
} from '../../types';
import { isReadyToContinue as underwritingMemoAddressIsReady } from '../../types/step-components/UnderwritingMemoAddress';

/**
 * The stateful context provided to a step rendering stack. Includes the
 * current step data, the step component registry, and a set of methods for
 * updating the step's values and submitting the step.
 */
export type StepCtx = {
  /**
   * Data about the current step, its content, and where it falls in the
   * workflow.
   */

  valuesJSON: any;

  step: ClaimWorkflowStepFragmentData;

  /**
   * The mapping of step component type names to React components that render
   * those step component types.
   */
  registry: StepComponentRegistry;

  /**
   * The values associated with any fields in the step. For example, if the
   * step has a component that includes a `field` value of `age`, and that
   * component calls `updateValue('age', 21)`, then `values.age` will be `21`.
   */
  values: Record<string, any>;

  /**
   * Any validation errors associated with any fields in the step. For example,
   * if the step has a component that includes a `field` value of `phone_number`,
   * and that component's validation schema rejects the user's existing input,
   * then `errors.phone_number` will be populated with the error message.
   */
  errors: Record<string, string>;

  /**
   * The number of validation errors in the step. This is used to determine
   * how errors are displayed in the UI.
   */
  errorCount: number;

  /**
   * Updates the value of a field in the step. This is used by step components
   * to update the values of their fields.
   */
  updateValue: (k: string | undefined, v: any) => void;

  /**
   * Checks all the required field values and, if they are all present, calls
   * `submit` with the current values. If any required fields are missing, then
   * there is no effect.
   */
  attemptSubmit: () => void;

  /**
   * Validates all the field values according to the `validationSchema` and
   * updates the `errors` record with any errors. If there are no errors, then
   * it calls the provider's `onSubmit` callback with the current `values`.
   */
  submit: (values: Record<string, any>, _?: { force: boolean }) => void;

  /**
   * Reset the error-related state, and set `values` to the given record.
   */
  resetWithValues: (values: Record<string, any>) => void;

  /**
   * Skips the current step. This means calling the provider's `onSubmit` callback
   * with the current `values` without doing any validation. Steps may optionally
   * specify a key-value pair to be added to the `values` record when the step is
   * skipped, via the `skip_field` and `skip_value` properties on the `step.content`
   * object.
   *
   * Notably, this does not check the `stepSkippable` flag, assuming that the
   * skip button will not be rendered if the step is not skippable.
   */
  skip: () => void;

  /**
   * Whether the current step can be skipped. This is determined by the presence
   * of a `skip_label` value on the `step.content` object.
   */
  stepSkippable: boolean;

  /**
   * Whether the current step requires manual confirmation to skip.
   */
  skipNeedsManualConfirm: boolean;

  /**
   * If the step requires manual confirmation before skipping, flag indicates if
   * the user has manually confirmed they want to skip the step.
   */
  skipConfirmed: boolean;

  /**
   * If the step requires manual confirmation before skipping, this function
   * should be called to indicate that the user has manually confirmed they want
   * to skip the step.
   */
  setSkipConfirmed: (confirmed: boolean) => void;

  /**
   * Whether the current step requires manual submission. This can be set in
   * several ways:
   * - If the `step.content.manual_submit_label` value is set
   * - If any React components registered to render this step's components
   *   have the `stepConfig.manualSubmit` flag set
   * - If this step contains any of a set of special components that require
   *   manual submission (namely `vehicle_damage_picker` and `interstitial`).
   */
  manualSubmitRequired: string | boolean;

  /**
   * If true, the current step's title should not be rendered by the StepRenderer as
   * usual. This flag is set by adding the `stepConfig.controlsTitle` flag on a
   * StepComponentFC implementation.
   */
  stepControlledTitle: boolean;

  /**
   * If true, the step should be rendered in a special "full-page height" mode.
   * This flag is set by adding the `stepConfig.fullPageHeight` flag on a
   * StepComponentFC implementation.
   */
  stepRequiresFullPageHeight: boolean;

  /**
   * Indicates whether the submit button should be enabled. This is normally
   * `true`, since submission enforces validation on most fields, but this flag
   * provides special handling for the `select_multi`, `bodily_injury`,
   * `vehicle_seatmap`, and `select_or_create` components. Eventually, these
   * exceptions probably don't need to be carved out, but that's a refactor for
   * another day.
   */
  stepReadyToContinue: boolean;
};

export const StepContext = createContext<StepCtx | null>(null);

/**
 * Provides a StepContext for a step rendering stack (`StepRenderer` and
 * `StepComponentRenderer`, as well as `StepComponentFC` implementations).
 */
export const StepProvider: React.FC<{
  step: ClaimWorkflowStepFragmentData;
  registry: StepComponentRegistry;
  onSubmit: (values: Record<string, any>) => void;
  values?: Record<string, any>;
  autoSubmit?: boolean;
}> = ({ step, registry, onSubmit, children, values: vals, autoSubmit }) => {
  const [values, setValues] = useState<Record<string, any>>(vals || {});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [errorCount, setErrorCount] = useState<number>(0);
  const [skipConfirmed, setSkipConfirmed_] = useState(false);

  useEffect(() => {
    if (
      step.content.skip_require_confirmation_checkbox &&
      step.content.skip_initially_confirmed
    ) {
      setSkipConfirmed(true);
    }
  }, [step.content.skip_initially_confirmed]);

  const updateValue = useCallback((k: string | undefined, v: any) => {
    if (k) {
      setValues(values => ({ ...values, [k]: v }));
    }
  }, []);

  useEffect(() => {
    if (autoSubmit && !manualSubmitRequired) {
      attemptSubmit();
    }
  }, [values]);

  const submit = useCallback(
    (valuesToSubmit: any, { force }: { force?: boolean } = {}) => {
      setErrors({});
      const extractInnerPath = (innerE: any): string => {
        if (innerE.path.startsWith('[')) {
          return unescapeComponentJSONFieldName(JSON.parse(innerE.path)[0]);
        }
        // if we are on seat selection and email is wrong format
        // we will get the type seat-selection-email-validation
        if (innerE.type && innerE.type === 'seat-selection-email-validation') {
          return innerE.type;
        }
        return unescapeComponentJSONFieldName(innerE.path);
      };
      // No validation
      if (force === true) {
        onSubmit(valuesToSubmit);
        return;
      }

      setTimeout(() => {
        const escapedValuesToSubmit = Object.keys(valuesToSubmit).reduce(
          (acc, key) => {
            acc[escapeComponentJSONFieldName(key)] = valuesToSubmit[key];
            return acc;
          },
          {} as Record<string, any>,
        );
        const schema = generateValidationSchema({ step });
        schema
          .validate(escapedValuesToSubmit, { abortEarly: false })
          .then(() => onSubmit(valuesToSubmit))
          .catch(e => {
            let errs: Record<string, string> = {};
            if (e.inner) {
              for (const innerE of e.inner) {
                const innerPath = extractInnerPath(innerE);
                errs[innerPath] = Array.isArray(innerE.errors)
                  ? innerE.errors.join('; ')
                  : innerE.errors;
              }

              let uncapturedErrors = [];
              for (const escapedPath in errs) {
                const path = unescapeComponentJSONFieldName(escapedPath);
                const c = step.content.step_components.find(com => {
                  return (
                    (com.field && path.startsWith(com.field)) ||
                    ('other_field' in com &&
                      com.other_field &&
                      path.startsWith(com.other_field))
                  );
                });
                if (
                  c &&
                  !stepComponentHasConfigFlag(c, 'controlsError', registry)
                ) {
                  uncapturedErrors.push(errs[path]);
                }
              }
              if (uncapturedErrors.length) {
                errs['default'] = uncapturedErrors
                  .filter((e, i, arr) => arr.indexOf(e) === i)
                  .join('; ');
              }
            } else {
              errs['default'] =
                'Failed to save. Please try again, or contact us.';
            }
            setErrors(errs);
            setErrorCount(errorCount + 1);
          });
      }, 0);
    },
    [step, onSubmit, errorCount],
  );

  (window as any).stepSubmit = submit;
  (window as any).step = step;

  const attemptSubmit = useCallback(() => {
    const hasFields = step.content.step_components.some(c => !!c.field);
    const allEntered = step.content.step_components[
      step.content.autosubmit_partial ? 'some' : 'every'
    ](c => (c.field ? typeof values[c.field] !== 'undefined' : true));
    if (allEntered && hasFields) {
      submit(values);
    }
  }, [step, values, submit]);

  const resetWithValues = useCallback(
    (values: Record<string, any>) => {
      setValues(values);
      setErrors({});
      setErrorCount(0);
      setSkipConfirmed(false);
    },
    [setValues, setErrors, setErrorCount],
  );

  const skip = useCallback(() => {
    submit(
      step.content.skip_field
        ? {
            [step.content.skip_field]: step.content.skip_value,
          }
        : values,
      { force: true },
    );
  }, [submit, step]);

  const setSkipConfirmed = (confirmed: boolean) => {
    setSkipConfirmed_(confirmed);
    if (confirmed) {
      setValues({});
    }
  };

  const stepSkippable = !!step.content.skip_label;
  const skipNeedsManualConfirm =
    stepSkippable && !!step.content.skip_require_confirmation_checkbox;

  const manualSubmitRequired =
    step.content.manual_submit_label ||
    step.content.step_components.some(
      c =>
        stepComponentHasConfigFlag(c, 'manualSubmit', registry) ||
        c.type === 'vehicle_damage_picker',
    ) ||
    step.content.step_components.every(
      c => c.type === 'interstitial' || c.type === 'scripting_instructions',
    ) ||
    step.content.step_components.some(
      // Since Select by default auto-submits once selected, if there's an existing value, require "Continue" click.
      c => c.type === 'select' && typeof c.existing_value !== 'undefined',
    );

  const stepRequiresFullPageHeight = step.content.step_components.some(c =>
    stepComponentHasConfigFlag(c, 'fullPageHeight', registry),
  );

  const stepControlledTitle = step.content.step_components.some(c =>
    stepComponentHasConfigFlag(c, 'controlsTitle', registry),
  );

  const stepReadyToContinue =
    !(
      step.content.require_one_of && Object.keys(values).every(k => !values[k])
    ) &&
    step.content.step_components.some(c => {
      return (
        !(
          c.type === 'string' &&
          c.mode === 'small_number' &&
          c.field &&
          values[c.field] === 0 &&
          c.required
        ) &&
        !(
          c.type === 'select_multi' &&
          c.field &&
          c.required &&
          !values[c.field]?.length
        ) &&
        !(
          (c.type === 'bodily_injury' ||
            (c.type === 'vehicle_seatmap' && c.single) ||
            c.type === 'select_or_create') &&
          c.field &&
          !values[c.field]
        ) &&
        !(
          (c.type === 'party_adder' ||
            c.type === 'vehicle_damage_region_picker' ||
            c.type === 'datetime_without_timezone' ||
            c.type === 'license_plate_selector' ||
            c.type === 'vehicle_occupant_entry_wizard' ||
            c.type === 'location') &&
          c.field &&
          !generateValidationSchema({ step }).isValidSync(values)
        ) &&
        !(
          c.type === 'underwriting_memo_address' &&
          c.field &&
          !underwritingMemoAddressIsReady(values[c.field])
        )
      );
    });

  /**
    If the values key is a stringified JSON object, it's a nested field
    Transform nested field entries 
    from:{"{'relation':{'index':0,'name':'hitAndRunParties','fields':[{'field':'type'}]}}": "Other"}
    to: { "type": "OTHER" }
    Keys are not always nested field though, in those cases captureNestedFields just mirrors what is in 'values'
    Current use-case for parsing nested keys is for client-side filtering
   */
  function captureNestedFields(values: any) {
    const result: Record<string, any> = {};

    for (let key in values) {
      try {
        const parsedKey = JSON.parse(key);
        mapFields(parsedKey, values[key], result);
      } catch (error) {
        result[key] = values[key];
      }
    }

    return result;
  }

  function mapFields(obj: any, value: string, result: any) {
    if (typeof obj === 'object' && obj !== null) {
      if ('field' in obj) {
        // If the object has a 'field' property, add its value to the result
        result[obj.field] = value?.[obj.field] || value;
      } else {
        // Otherwise, recursively call mapFields for each property
        for (let prop in obj) {
          mapFields(obj[prop], value, result);
        }
      }
    }
  }

  const valuesJSON = captureNestedFields(values);

  return (
    <StepContext.Provider
      value={{
        step,
        valuesJSON,
        registry,
        values,
        errors,
        errorCount,
        updateValue,
        submit,
        attemptSubmit,
        skip,
        stepSkippable,
        skipNeedsManualConfirm,
        skipConfirmed,
        setSkipConfirmed,
        resetWithValues,
        manualSubmitRequired,
        stepControlledTitle,
        stepRequiresFullPageHeight,
        stepReadyToContinue,
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

/**
 * This hook is used to access the `StepContext` from the step rendering component
 * stack, as well as step components themselves.
 */
export const useStep = () => {
  const ctx = useContext(StepContext);
  if (!ctx) {
    throw new Error('No StepContext found');
  }
  return ctx;
};
