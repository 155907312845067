import classNames from 'classnames';
import { stripEmojis } from '~/src/utils';

import { dataTestId } from '../../../utilities/dataTestId';
import ExtendedSelectOrCreateWithSearch from './ExtendedSelectOrCreateWithSearch';

import type {
  StepComponentFC,
  StepComponentSharedProps,
  SelectOrCreateStepComponentSpec,
} from '@assured/step-renderer';

interface SelectOrCreateProps
  extends StepComponentSharedProps<SelectOrCreateStepComponentSpec, any> {
  forceSubmit: () => void;
}
const SelectOrCreate: StepComponentFC<SelectOrCreateProps> = ({
  step_component,
  updateValue,
  className,
  forceSubmit,
  primaryValue,
}) => {
  // this should be a different component.
  if (step_component.mode === 'extended') {
    return (
      <ExtendedSelectOrCreateWithSearch
        step_component={step_component}
        updateValue={updateValue}
        className={className}
        forceSubmit={forceSubmit}
        primaryValue={primaryValue}
      />
    );
  }

  const options = step_component.options
    .concat([
      { label: step_component.create.label || 'Add new person', new: true },
    ])
    .concat(
      step_component.create.include_empty_option
        ? [{ label: "I don't know", __unknown__: true }]
        : [],
    );

  let selectedOption = options.find(
    x =>
      x === primaryValue ||
      (x.new && primaryValue?.new) ||
      (step_component.use_string_value &&
        typeof primaryValue === 'string' &&
        typeof x === 'object' &&
        x[step_component.create.fields[0].key] === primaryValue) ||
      (typeof primaryValue === 'object' &&
        typeof x === 'object' &&
        Object.keys(primaryValue).length &&
        step_component.create.fields
          .map(f => f.key)
          .every(k => primaryValue[k] && primaryValue[k] === x[k])),
  );
  if (
    selectedOption?.new &&
    !step_component.create.fields.some(x => !!selectedOption?.[x.key])
  ) {
    selectedOption = undefined;
  }

  const isUnknown = primaryValue?.__unknown__ === true;
  const isOther = primaryValue && !isUnknown && !selectedOption;

  return (
    <div className={className}>
      {options
        .map(option => {
          return {
            ...option,
            label: option.label.replace(/undefined/g, '').trim(),
          };
        })
        .filter(option => option.label)
        .map(option => {
          const selected =
            selectedOption === option ||
            (option.new && isOther) ||
            (option.__unknown__ && isUnknown);

          return (
            <label
              key={option.label.toString()}
              className={classNames(
                'flex border-solid border-2 rounded-lg px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out',
                selected && 'bg-blue-100 border-blue-300',
                option.new && selected ? 'py-3' : 'py-2',
                'my-4',
              )}
            >
              <input
                type="checkbox"
                data-testid={option.testId || dataTestId(option.value)}
                className="form-radio focus:shadow-none focus:border-gray-300 cursor-pointer h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                checked={selected}
                onChange={() => {
                  if (step_component.use_string_value) {
                    updateValue(
                      step_component.field,
                      option[step_component.create.fields[0].key] || option,
                    );
                  } else {
                    updateValue(
                      step_component.field,
                      option.__unknown__
                        ? {
                            ...option,
                            ...step_component.create.base,
                          }
                        : option,
                    );
                  }
                }}
              />
              <div className="flex-1 text-cool-gray-700 ml-4 text-left">
                {option.label}
                {option.new && selected ? (
                  <div>
                    {step_component.create.fields.map((f, i) => {
                      return (
                        <div key={f.key} className="flex items-center mt-1">
                          <input
                            data-testid="newEntry"
                            autoFocus={i === 0}
                            type="text"
                            className="textbox"
                            placeholder={f.label}
                            value={
                              (step_component.use_string_value &&
                              typeof primaryValue === 'string'
                                ? primaryValue
                                : primaryValue?.[f.key]) || ''
                            }
                            onChange={e => {
                              const value = stripEmojis(e.target.value);
                              if (step_component.use_string_value) {
                                updateValue(step_component.field, value);
                              } else {
                                updateValue(step_component.field, {
                                  ...step_component.create.base,
                                  ...option,
                                  [f.key]: value,
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </label>
          );
        })}
    </div>
  );
};

export default SelectOrCreate;

SelectOrCreate.stepConfig = {
  manualSubmit: true,
};
