import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import GenericErrorPage from '../GenericErrorPage';
import { LoadingOverlay } from '../LoadingOverlay';
import { BEGIN_2_FAC_AUTH } from './begin2FactAuth';
import ExistingDraftModal from './ExistingDraftModal';
import { RESUME_WORKFLOW_DRAFT } from './resumeWorkflowDraftGQL';
import { START_NEW_CLAIM_WORKFLOW } from './startNewClaimWorkflowGQL';
import TwoFactorModal from './TwoFactorModal';
import { BeginWorkflowParams } from '~/src/components/BeginWorkflow/types';

type BeginWorkflowProps = {
  startParams: BeginWorkflowParams;
  claimId?: string;
  firstName?: string;
  setShowDraftModal: (show: boolean) => void;
  showDraftModal: boolean;
  startWorkflow: boolean;
  requires2Fac: boolean;
  dispatchId?: string;
};

export const BeginWorkflow = ({
  startParams,
  claimId,
  firstName,
  showDraftModal,
  setShowDraftModal,
  startWorkflow,
  requires2Fac,
  dispatchId,
}: BeginWorkflowProps) => {
  const [twoFactor, setTwoFactor] = useState<
    | {
        success: boolean;
        recipient: string;
      }
    | undefined
  >();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const history = useHistory();

  const [
    begin2FacAuth,
    { loading: begin2FacAuthLoading, error: begin2FacAuthError },
  ] = useMutation(BEGIN_2_FAC_AUTH, {
    onCompleted: ({ begin2FacAuth: { phoneNumber } }) => {
      window.analytics.track('Existing Draft Prompt Shown');
      setTwoFactor({ success: true, recipient: phoneNumber });
      setShowAuthModal(true);
    },
  });

  const [resumeWorkflowDraft, { loading: resumeLoading, error: resumeError }] =
    useMutation(RESUME_WORKFLOW_DRAFT, {
      onCompleted: ({ resumeWorkflowDraft: { success, data } }) => {
        if (success) {
          window.analytics.track('Existing Draft Successfully Resumed');
          history.push({
            pathname: `/workflows/${data?.workflowId}`,
          });
        } else {
          setTwoFactor({
            recipient: twoFactor?.recipient || '',
            success,
          });
        }
      },
    });

  const [startNewClaimWorkflow, { loading: startLoading, error: startError }] =
    useMutation(START_NEW_CLAIM_WORKFLOW, {
      onCompleted: ({
        startNewClaimWorkflow: {
          data: { id },
        },
      }) =>
        history.push({
          pathname: `/workflows/${id}`,
        }),
    });
  // If we receive the startWorkflow flag that means there were no
  // drafts to resume and we should begin a fresh workflow
  useEffect(() => {
    if (startWorkflow) {
      startNewClaimWorkflow({
        variables: {
          ...startParams,
          dispatchId,
          terminateDraftClaimId: claimId,
        },
      });
    }
  }, [startWorkflow]);

  const discardDraft = () => {
    window.analytics.track('Existing Draft Chose To Start Over');
    setShowDraftModal(false);
    startNewClaimWorkflow({
      variables: {
        ...startParams,
        dispatchId,
        terminateDraftClaimId: claimId,
      },
    });
  };

  const resumeDraft = () => {
    window.analytics.track('Existing Draft Chose To Resume');
    setShowDraftModal(false);

    if (!requires2Fac) {
      // Short circuit: if we don't need 2FA, resume the draft immediately
      // and return. This way we don't initiate sending a 2FA code.
      resumeWorkflowDraft({
        variables: {
          ...startParams,
          draftClaimId: claimId,
        },
      });
      return;
    }

    begin2FacAuth();
  };

  const discardTwoFactor = () => {
    window.analytics.track('Existing Draft 2FA Gave Up And Start Over');
    startNewClaimWorkflow({
      variables: {
        ...startParams,
        terminateDraftClaimId: claimId,
      },
    });
  };

  const submitTwoFactor = (code: string) => {
    resumeWorkflowDraft({
      variables: {
        authenticationCode: code,
        draftClaimId: claimId,
      },
    });
  };

  const error = resumeError || startError || begin2FacAuthError;
  if (error) {
    return <GenericErrorPage modal={true} error={error} />;
  }

  if (resumeLoading || startLoading || begin2FacAuthLoading) {
    return <LoadingOverlay />;
  }
  return (
    <>
      <ExistingDraftModal
        open={showDraftModal}
        firstName={firstName}
        onDiscard={discardDraft}
        onContinueFiling={resumeDraft}
      />
      <TwoFactorModal
        open={showAuthModal}
        data={twoFactor || undefined}
        onDiscard={discardTwoFactor}
        onSubmitCode={submitTwoFactor}
      />
    </>
  );
};
